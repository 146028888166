$anim-easing-easeOutQuad: cubic-bezier(0.250, 0.460, 0.450, 0.940);
$anim-easing-easeOutCubic: cubic-bezier(0.215, 0.610, 0.355, 1.000);
$anim-easing-easeInOutQuad: cubic-bezier(0.455, 0.030, 0.515, 0.955);
$anim-duration: .6s;
$anim-postfix-enter: 'enter';
$anim-postfix-leave: 'leave';

$overlay-background: rgba(0,0,0,.6);

$grid-spacing: 8px;
